import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SectionTitle from "../components/sectiontitle";
import SEO from "../components/seo";

const Container = styled.div`
  display: grid;
  row-gap: 12px;
  justify-items: center;
  margin: 16px auto;
  max-width: 790px;
  
  p {
    text-align: left;
    width: 100%;
  }
  
  p.sign {
    text-align: right;
  }
  
  a {
    text-decoration: none;
    color: black;
  }

`

const AboutUs = () => (
  <Layout>
    <SEO title="わたしたちについて" keywords={[`働き方`, `リモートデスクトップ`, `時短`]} />
    <SectionTitle value="わたしたちについて" button="記事一覧へ" visible="off" link="/" />
    <Container>
      <h2>
        splash mediaは、肩肘張らず、前向きに人生を楽しむ人たちを応援するメディアです。
      </h2>
      <p>
        splash mediaは、「リモートデスクトップ」というサービスを提供しているスプラッシュトップ株式会社が運営しています。
      </p>
      <p>
        「リモートデスクトップ」って、あまり聞き覚えがない言葉かもしれません。
      </p>
      <p>
        簡単に言うと、「会社や自宅のパソコンを外出先のスマホやタブレットなどから操作できる」サービスです。最近では、働き方改革の一環として、在宅勤務やリモートワーク制度のためのツールとして導入される機会がとても増えてきました。
      </p>
      <p>
        そんなシステムを提供しているわたしたちだからこそ、時間や場所、ライフステージやライフスタイルにとらわれない“新しい働き方"と"健康的で遊び心のある暮らし"を応援する情報を発信していきたいと思っています。
      </p>
      <p className="sign">
        スプラッシュトップ株式会社<br/>
        <a href="https://www.splashtop.co.jp" target="_blank">https://www.splashtop.co.jp</a>

      </p>
    </Container>
  </Layout>
)



export default AboutUs